.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
} 

.App .nav-item {
  margin:10px;
}