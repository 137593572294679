  .Menu{
    padding: 10px;
  }
  .Menu ul {
    list-style-type: none;
  }

 
  .Menu .accordion-toggle{
    color: grey;
    border-color: transparent;
    background: white;
    cursor: pointer;
    font-weight: 600;
    border-radius: 16px;
    font-size:1rem;
    padding: .50rem .75rem;
    margin-bottom: 0;

  }

  .Menu .accordion-toggle:focus{
    background: #f2f2f2;
    border-color: none !important;
  }

  .Menu .accordion-toggle:hover{
    background: #f2f2f2;
    border-color: none !important;
    color: #02d0a7;

  }
  .Menu .accordion>ul{
    padding-left: 0px;


  }

  
  