@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .PublicArticle h1 {
    color: blue;
  }

  .PublicArticle pre {
    display: block;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 14px;
    line-height: 28px;
    border-radius: 0;
    overflow-x: auto;
    word-break: break-word;
  }
  .PublicArticle h3 {
    font-size: 18px;
    margin: 18px 0;
    padding: 0px 0;
    line-height: 1.7;
    border: none;
  }
  